<template>
  <nav class="container">
    <a class="main-logo" href="/">
      <img class="carbon21-logo" src="@/assets/logo.svg" alt="Carbon21 Logo" />
      <h2 class="carbon21-text">Carbon<sup>21</sup></h2>
    </a>
    <div class="align-right">
      <ul class="nav-links">
        <li><a class="menu-link" href="#c21coin">The coin</a></li>
        <li><a class="menu-link" href="#c21diamonds">Diamond Process</a></li>
        <li><a class="menu-link" href="#collections">C<sup>21</sup> Cards</a></li>
        <!-- <li><router-link class="menu-link" to="#c21-cards">C<sup>21</sup> Cards</router-link></li> -->
        <!-- <li><router-link class="menu-link" to="/collections">Browse Collections</router-link></li> -->
      </ul>
      <div class="social-icons">
        <a class="icon twitter" href="https://twitter.com/ProjectCarbon21" target="_blank">
          <img class="icon-img" src="@/assets/socials/twitter.svg" alt="Twitter" />
        </a>
        <a class="icon telegram" href="https://t.me/ProjectCarbon21" target="_blank">
          <img class="icon-img" src="@/assets/socials/telegram.svg" alt="Telegram" />
        </a>
        <!-- <a  class="icon discord" href="https://discord.com" target="_blank">
          <img src="@/assets/socials/discord.svg" alt="Discord" />
        </a> -->
      </div>
      <!-- <div class="button-section">
        <router-link class="the-cube" to="/thecube">CB21 Cards</router-link>
      </div> -->
    </div>
  </nav>
</template>

<style scoped>
nav {
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;

  @media (max-width: 768px) {
    padding: 10px;
  }

  .main-logo {
    height: 50px;
    display: flex;
    align-items: center;
    text-decoration: none;

    .carbon21-logo {
      height: 50px;
      margin-right: 15px;
    }

    .carbon21-text {
      font-size: 1.25rem;
      font-weight: 600;
      margin: 0px;
      color: #151414;
      text-decoration: none !important;
    }
  }

  .align-right {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .nav-links {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      list-style: none;
      margin: 0;
      padding: 0;
      margin-right: 10px;

      @media (max-width: 768px) {
        display: none;
      }

      li {
        margin: 0 10px;
        font-size: 1.5rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.menu-link {
  font-size: 1.05rem;
  font-weight: 600;
  text-transform: capitalize;
  color: #151414;
  text-decoration: none !important;
  margin-right: 20px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #5d616a;
  }
}

.social-icons {
  
  .icon {
    margin: 0 5px;
    transition: all 0.3s ease-in-out;

    .icon-img {
      width: 30px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.the-cube {
  padding: 6px 20px 6px 18px;
  border-radius: 4px;
  background: var(--blue-gradient, linear-gradient(168deg, #55A7C1 0%, #74CBE7 50%, #55A7C1 100%));
  text-decoration: none !important;
  color: #fff;
  font-size: 1.6rem;
  margin-left: 15px;
  transition: all 0.5s ease-in-out;

  &:hover {
    background: var(--blue-hover, linear-gradient(168deg, #7CC2D9 0%, #92DDF4 50%, #7CC2D9 100%));
    cursor: pointer;
  }
}
</style>