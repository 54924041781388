<template>
  <div>
    <header>
      <div class="header-content container">
        <div class="left-card">
          <img src="@/assets/header/left-card.png" alt="" />
        </div>
        <div class="content">
          <h1 class="main-title">C<sup>21</sup> is for 💎🤲</h1>
          <div class="lock-coins">
            <span class="yellow-ish">LOCK YOUR COINS</span>
            <span> > </span>
            <span class="blue-ish">CREATE DIAMONDs NFTs</span>
          </div>
          <div class="countdown-container desktop">
            <h4 class="countdown-title">Genesis card Released!</h4>
            <a href="/collections/0xd010c0801d528d14301007da72c70cbb552c281f/0" class="cube-button button disabled">Mint Now</a>
          </div>
        </div>
        <div class="right-card">
          <img src="@/assets/header/right-card.png" alt="" />
        </div>
      </div>
      <div class="countdown-container mobile">
        <h4 class="countdown-title">Genesis card Released!</h4>
        <a href="/collections/0xd010c0801d528d14301007da72c70cbb552c281f/0" class="cube-button button disabled">Mint Now</a>
      </div>
    </header>
    <section id="collections" class="dark">
      <div class="container">

        <!-- <h2>🔎 Browse Diamond Collections</h2> -->

        <!-- <div class="search">
          <input class="search-box" type="text" placeholder="Search by template, artist." />
          <span class="search-button"><img src="@/assets/search-button.svg" ></span>
        </div> -->

        <div v-for="collection in collections" class="collection carbon21" :key="collection.name">
          <h2 class="cards-title">
            <a :href="`/collections/${collection.diamondCollectionAddress}`">
              <img class="coin-logo" :src="collection.logoURI" alt="carbon21" />
              <span v-if="collection.name == 'C21 Official Collection'">Dimension21</span>
              <span v-else>{{ collection.name }}</span>
            </a>
          </h2>

          <div class="cards latest-cards">
            <a class="card clickable" v-for="blueprint in collection.activeBlueprints" :key="blueprint.nftId" :href="`/collections/${collection.diamondCollectionAddress}/${blueprint.nftId}`">
              <h4>#{{blueprint.nftId}} - {{ blueprint.name }}</h4>
              <div class="image-wrapper">
                <video v-if="blueprint.fileExtension == '.mp4'" class="image" controls>
                  <source :src="collection.imageBaseURI+''+blueprint.blueprintId+blueprint.fileExtension" type="video/mp4">
                  Your browser does not support the video tag.
                </video>
                <img v-else class="image" :src="collection.imageBaseURI+''+blueprint.blueprintId+blueprint.fileExtension" alt="Card Image" />
              </div>
              <div class="card-info">
                <span class="number">{{ blueprint.minted }}/{{ blueprint.maxSupply }}</span>
                <span v-if="blueprint.price" class="price"><span class="currency"></span>{{ blueprint.price }} <img class="coin-logo" :src="collection.logoURI" alt="carbon21" /></span>
              </div>
              <div v-if="blueprint.mintStatus == 'infinite'" class="mint-status minting infinite">∞</div>
              <div v-else-if="blueprint.mintStatus == 'complete'" class="mint-status complete">
                Mint complete
              </div>
              <div v-else-if="blueprint.mintStatus == 'minting'" class="mint-status minting">
                <span v-if="blueprint.endDate < 100000000000">
                  Minting for {{ blueprint.remainingTime }}
                </span>
                <span v-else>
                  Minting
                </span>
              </div>
              <div v-else-if="blueprint.mintStatus == 'not-started'" class="mint-status not-started">
                Minting in {{ blueprint.remainingTime }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section id="c21diamonds">
      <div class="container">
        <div class="smaller-container">
          <h2><span class="icon">💎</span> THE DIAMONDs PROCESS</h2>

          <h3>Holders: Lock $ERC20, mint new NFTs </h3>

          <p>If you choose to lock one of the accepted $ERC20*, You can choose among the available Blueprints for that
            coin and mint Diamond NFTs.</p>
          <p>As a real diamond hand, you won’t be able to withdraw your ERC20 during the whole time of the lock.</p>
          <p>Minting will start on Sunday, 29th October</p>
          <p class="info">*Examples of ERC20: $C21, $KEKO, $PEPE, and $BAG</p>

          <div class="explanation minting">
            <div class="step">
              <img src="@/assets/cards-explain/coins.png" alt="Choose coin" />
              <h4 class="info">Select An Available $ERC20</h4>
            </div>
            <div class="arrow">
              <img src="@/assets/cards-explain/arrow.png" alt="" />
            </div>
            <div class="step">
              <img src="@/assets/cards-explain/blueprints.png" alt="Choose Blueprint" />
              <h4 class="info">ChooSe A BluePrint</h4>
            </div>
            <div class="arrow">
              <img src="@/assets/cards-explain/arrow.png" alt="" />
            </div>
            <div class="step">
              <img src="@/assets/cards-explain/mints.png" alt="Mint Diamonds" />
              <h4 class="info">Lock $Coin, Mint DiamondS NFTs</h4>
            </div>
          </div>

          <h3>Artists: Burn $C21, create blueprints </h3>

          <p>As an Artist, you can create blueprints for your favorite coins. It will then be available in the coin
            Diamond collection for minting (until the supply is depleted).</p>
          <p>Blueprints can be created with supplies from 21 to 1000, Free or Not, and for a limited time. After being
            Minted the Diamond NFTs will be tradable on any NFTs platform (Opensea, Blur, ...)</p>

          <div class="explanation blueprint">
            <div class="step">
              <img src="@/assets/cards-explain/coins.png" alt="Choose coin" />
              <h4 class="info">Select An Available $ERC20</h4>
            </div>
            <div class="arrow">
              <img src="@/assets/cards-explain/arrow.png" alt="" />
            </div>
            <div class="step">
              <img src="@/assets/cards-explain/design-blueprint.png" alt="Choose Blueprint" />
              <h4 class="info">Select your Design, Its Supply, name, price, etc...</h4>
            </div>
            <div class="arrow">
              <img src="@/assets/cards-explain/arrow.png" alt="" />
            </div>
            <div class="step">
              <img src="@/assets/cards-explain/burn.png" alt="Mint Diamonds" />
              <h4 class="info">Generate your BluePrint by burning some $C21</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="c21coin" class="dark">
      <div class="container">
        <div class="smaller-container">
          <h2><span class="icon">🪙</span> WHAT IS CARBON<sup>21</sup>?</h2>
          <div>
            <p>
              $C21 is the first token engineered to create diamond hands ⚙️.
            </p>
            <p>
              Based on Ethereum, it is the base of a diamond system which can lock various ERC20 tokens and to create new
              - diamonds - NFTs.
            </p>
            <p>
              $C21 base contract was made as a simple ERC20, to keeps things safe.
            </p>
            <div class="contract-address">
              <span class="address"><a href="https://etherscan.io/token/0x2C642Cd3beD9b4e4EEEc493611779f13efB502F1"
                  target="_blank">0x2C642Cd3beD9b4e4EEEc493611779f13efB502F1</a></span>
              <span class="info">$C21 SMART CONTRACT ADDRESS</span>
            </div>
            <p>
              Start using $C21, or your favorite coin, to mint new NFTs. LFG! 🏁
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CollectionService from '@/services/CollectionService';

export default {
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      time: {},
      trackers: ['days', 'hours', 'minutes', 'seconds'],
      web3: null,
      collections: [],
      diamondFactoryContract: null,
      activeBlueprints: [],
      countdowns: {},
      blueprintIntervals: {},
    };
  },
  async mounted() {
    this.collectionService = new CollectionService();
    await this.fetchCollections();
    this.startAllBlueprintCountdowns();
  },
  methods: {
    startAllBlueprintCountdowns() {
      this.collections.forEach(collection => {
        collection.activeBlueprints.forEach(blueprint => {
          this.startBlueprintCountdown(blueprint);
        });
      });
    },
    startBlueprintCountdown(blueprint) {
      const updateCountdown = () => {
        const now = new Date();
        let remaining;

        if (now < new Date(blueprint.startDate*1000)) {
          // If the current date is before the start date
          remaining = this.getTimeRemaining(blueprint.startDate*1000);
          blueprint.remainingTime = `${remaining}`;
        } else if (now >= new Date(blueprint.startDate*1000) && now < new Date(blueprint.endDate*1000)) {
          // If the current date is between the start date and end date
          remaining = this.getTimeRemaining(blueprint.endDate*1000);
          blueprint.remainingTime = `${remaining}`;
        } else {
          // If the current date is after the end date
          blueprint.remainingTime = 'Minting has ended';
          clearInterval(this.blueprintIntervals[blueprint.nftId]);
        }
      };

      this.blueprintIntervals[blueprint.nftId] = setInterval(updateCountdown, 1000);
      updateCountdown(); // Initial update
    },
    getTimeRemaining(endtime) {
      const total = endtime - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));

      let timeString = '';

      if (days > 0) {
        timeString += `${days}d `;
      }
      if (hours > 0 || days > 0) {
        timeString += `${hours}h `;
      }
      timeString += `${minutes}min ${seconds}s`;

      return total > 0 ? timeString : 'Minting has ended';
    },
    async fetchCollections() {
      const { collections, countdowns } = await this.collectionService.fetchAllCollections();
      this.collections = collections;
      console.log("collections: ");
      console.log(collections);
      this.countdowns = countdowns;
    }
  },
  beforeUnmount() {
    // Clear all intervals when the component is destroyed to prevent memory leaks
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  },
};
</script>