import Web3 from 'web3';
import DiamondFactoryABI from '../abis/DiamondFactory.json';
import DiamondCollectionABI from '../abis/DiamondCollection.json';
//import LockerRoomABI from '../abis/LockerRoom.json';
import RulesetABI from '../abis/RulesetManager.json';
import ERC20ABI from '../abis/ERC20.json';
import { prepareWriteContract, writeContract, waitForTransaction } from '@wagmi/core'
import axios from 'axios';

const RPC_PROVIDER = process.env.VUE_APP_RPC_PROVIDER;
const DIAMOND_FACTORY_CONTRACT_ADDRESS = process.env.VUE_APP_DIAMOND_FACTORY_CONTRACT_ADDRESS;
const ValidationStatus = {
  Waiting: 0,
  Accepted: 1,
  Refused: 2
};
const dateOptions = {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
};

class CollectionService {
  constructor() {
    this.web3 = new Web3(RPC_PROVIDER);
    this.diamondFactoryContractAddress = DIAMOND_FACTORY_CONTRACT_ADDRESS;
    this.diamondFactoryContract = new this.web3.eth.Contract(
      DiamondFactoryABI,
      this.diamondFactoryContractAddress
    );
    this.collections = [];
    this.apiBaseUrl = 'https://api.carbon21.io';
    //this.apiBaseUrl = 'http://localhost:3003';
  }

  // API FETCHING

  async getAllCollections() {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/collections`);
      return response.data;
    } catch (error) {
      console.error('Error fetching collections:', error);
      throw error;
    }
  }

  async getAllBlueprintsFromCollection(collectionId) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/collections/${collectionId}/blueprints`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching blueprints for collection with ID ${collectionId}:`, error);
      throw error;
    }
  }

  async getBlueprintFromCollection(collectionId, blueprintId) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/collections/${collectionId}/blueprints/${blueprintId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching blueprint with ID ${blueprintId} from collection ${collectionId}:`, error);
      throw error;
    }
  }

  async getAllNFTsFromCollection(collectionId) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/collections/${collectionId}/nfts`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching NFTs for collection with ID ${collectionId}:`, error);
      throw error;
    }
  }

  async getNFTFromCollection(collectionId, nftId) {
    try {
      const response = await axios.get(`${this.apiBaseUrl}/collections/${collectionId}/nfts/${nftId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching NFT with ID ${nftId} from collection ${collectionId}:`, error);
      throw error;
    }
  }

  async fetchAllCollections() {
    const collections = await this.getAllCollections();
    let countdowns = {};

    for (let i = 0; i < collections.length; i++) {
      collections[i]["logoURI"] = collections[i].imageBaseURI + "logo.png";
      collections[i]["activeBlueprints"] = await this.getAllBlueprintsFromCollection(i+1);
      collections[i]["activeBlueprints"] = this.updateMintStatus(collections[i]["activeBlueprints"]);
    }
    this.collections = collections;

    return {
      collections: this.collections,
      countdowns: countdowns
    };
  }

  // Utilities

  updateMintStatus(blueprints) {
    for (let i = 0; i < blueprints.length; i++) {
      blueprints[i]["mintStatus"] = this.getMintStatus(blueprints[i].startDate, blueprints[i].endDate);
      if (blueprints[i].maxSupply > 10000000000) {
        blueprints[i]["maxSupply"] = "∞";
      }
    }
    console.log(blueprints);
    return blueprints;
  }

  getMintStatus(startDate, endDate) {
    const now = new Date().getTime() / 1000;
    if (now < startDate) {
      return 'not-started';
    } else if (endDate > 100000000000){
      return 'infinite';
    } else if (now > endDate) {
      return 'complete';
    } else {
      return 'minting';
    }
  }

  getTimeRemaining(endtime) {
    const total = endtime - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    return total > 0
      ? `${days}d ${hours}h ${minutes}min ${seconds}s`
      : 'Minting has ended';
  }

  // WEB3 FETCHING

  async getCollectionCount() {
    return await this.diamondFactoryContract.methods.collectionCount().call();
  }

  async getCollectionDetails(collectionIndex) {
    return await this.diamondFactoryContract.methods.collections(collectionIndex).call();
  }

  async getBlueprintCount(collectionAddress) {
    const contract = new this.web3.eth.Contract(this.DiamondCollectionABI, collectionAddress);
    return await contract.methods.blueprintCount().call();
  }

  isActiveBlueprint(blueprintProperties) {
    return parseInt(blueprintProperties.validationStatus) === ValidationStatus.Accepted;
  }

  setupCountdown(id, endtime) {
    this.countdowns[id] = setInterval(() => {
      const remaining = this.getTimeRemaining(endtime);
      if (remaining === 'Minting has ended') {
        clearInterval(this.countdowns[id]);
      } else {
        this.countdowns[id] = remaining;
      }
    }, 1000);
  }

  async fetchCollections() {
    const numberOfCollections = await this.getCollectionCount();
    let countdowns = {};

    console.log("numberOfCollections");
    console.log(parseInt(numberOfCollections));

    for (let i = 1; i < parseInt(numberOfCollections)+1; i++) {
      let collectionDetails = await this.getCollectionDetails(i);

      const collection = {
        collectionAddress: collectionDetails.collectionAddress,
        rulesetAddress: collectionDetails.ruleSetAddress,
        activeBlueprints: []
      };

      const diamondCollectionContract = new this.web3.eth.Contract(
        DiamondCollectionABI,
        collection.collectionAddress
      );

      const rulesetContract = new this.web3.eth.Contract(
        RulesetABI,
        collection.rulesetAddress
      );

      collection.name = await diamondCollectionContract.methods.name().call();
      collection.symbol = await diamondCollectionContract.methods.symbol().call();
      collection.imageBaseURI = await diamondCollectionContract.methods.imageURI().call();
      collection.logoURI = collection.imageBaseURI + "logo.png";
      console.log("collectionDetails");
      console.log(collection);

      let blueprintsCount = await diamondCollectionContract.methods.blueprintCount().call();
      
      blueprintsCount = parseInt(blueprintsCount)+1;
      console.log("Nombre de blueprints:"+ blueprintsCount);

      for (let j = 0; j < blueprintsCount; j++) {
        const activeBlueprint = await this.fetchActiveBlueprint(j, diamondCollectionContract, collection, rulesetContract);
        if (activeBlueprint.length > 0) {
          collection.activeBlueprints.push(activeBlueprint[0]);
        }
        console.log(collection.activeBlueprints);
      }

      this.collections.push(collection);
    }
    return {
      collections: this.collections,
      countdowns: countdowns
    };
  }

  async fetchCollection(collectionAddress) {
    let countdowns = {};

    console.log("collectionAddress");
    console.log(collectionAddress);

    const collection = {
      collectionAddress: collectionAddress,
      activeBlueprints: []
    };

    const diamondCollectionContract = new this.web3.eth.Contract(
      DiamondCollectionABI,
      collectionAddress
    );

    const collectionId = await this.getCollectionId(collectionAddress);
    const collectionDetails = await this.getCollectionDetails(collectionId);

    const rulesetContract = new this.web3.eth.Contract(
      RulesetABI,
      collectionDetails.rulesetAddress
    );

    collection.name = await diamondCollectionContract.methods.name().call();
    collection.symbol = await diamondCollectionContract.methods.symbol().call();
    collection.imageBaseURI = await diamondCollectionContract.methods.imageURI().call();
    collection.logoURI = collection.imageBaseURI + "logo.png";
    
    console.log("collectionDetails");
    console.log(collection);

    let blueprintsCount = await diamondCollectionContract.methods
      .blueprintCount()
      .call();
    
    blueprintsCount = parseInt(blueprintsCount)+1;
    console.log("Nombre de blueprints:"+ blueprintsCount);

    for (let j = 0; j < blueprintsCount; j++) {
      const activeBlueprint = await this.fetchActiveBlueprint(j, diamondCollectionContract, collection, rulesetContract);
      if (activeBlueprint.length > 0) {
        collection.activeBlueprints.push(activeBlueprint[0]);
      }
      console.log(collection.activeBlueprints);
    }

    return {
      collection: collection,
      countdowns: countdowns
    };
  }

  async fetchNFTDetails(collectionAddress, nftId) {
    const diamondCollectionContract = new this.web3.eth.Contract(
      DiamondCollectionABI,
      collectionAddress
    );
    
    let countdowns = {};
    const collection = {
      collectionAddress: collectionAddress
    };

    const collectionId = await this.getCollectionId(collectionAddress);
    const collectionDetails = await this.getCollectionDetails(collectionId);

    const rulesetContract = new this.web3.eth.Contract(
      RulesetABI,
      collectionDetails.ruleSetAddress
    );

    collection.name = await diamondCollectionContract.methods.name().call();
    collection.symbol = await diamondCollectionContract.methods.symbol().call();
    collection.imageBaseURI = await diamondCollectionContract.methods.imageURI().call();
    collection.logoURI = collection.imageBaseURI + "logo.png";
    
    console.log("collectionDetails");
    console.log(collection);

    const blueprintId = await diamondCollectionContract.methods.getBlueprintId(nftId).call();
    const blueprint = await this.fetchActiveBlueprint(blueprintId, diamondCollectionContract, collection, rulesetContract);
  
    return {
      collection: collection,
      blueprint: blueprint,
      countdowns: countdowns
    };
  }

  async getBlueprint(blueprintId, diamondCollectionContract) {
    return await diamondCollectionContract.methods.blueprints(blueprintId).call();
  }

  async getBlueprintProperties(blueprintId, diamondCollectionContract) {
    return await diamondCollectionContract.methods.blueprintProperties(blueprintId).call();
  }

  async getCollectionId(collectionAddress) {
    const numberOfCollections = await this.getCollectionCount();

    for (let i = 1; i < parseInt(numberOfCollections)+1; i++) {
      let collectionDetails = await this.getCollectionDetails(i);

      if(collectionDetails.collectionAddress.toLowerCase() == collectionAddress.toLowerCase()) {
        return i;
      }
    }
  }

  async fetchMintInformations(collectionAddress, nftId, userAddress) {
    const collectionId = await this.getCollectionId(collectionAddress);
    const collectionDetails = await this.getCollectionDetails(collectionId);
    const diamondCollectionContract = new this.web3.eth.Contract(
      DiamondCollectionABI,
      collectionAddress
    );
    const blueprintId = parseInt(await diamondCollectionContract.methods.getBlueprintId(nftId).call());
    const tokenAddress = await diamondCollectionContract.methods.tokenAddress().call();

    const mintInformations = {
      collectionId: collectionId,
      tokenAddress: tokenAddress,
      lockerRoomAddress: collectionDetails.lockerRoomAddress,
      factoryAddress: DIAMOND_FACTORY_CONTRACT_ADDRESS,
      rulesetAddress: collectionDetails.ruleSetAddress,
      blueprintId: blueprintId,
      nftId: nftId
    };
    mintInformations["minLockDuration"] = parseInt(await diamondCollectionContract.methods.minLockDuration().call());
    mintInformations["maxLockDuration"] = parseInt(await diamondCollectionContract.methods.maxLockDuration().call());
    mintInformations["lockPercentage"] = parseInt(await diamondCollectionContract.methods.lockPercentage().call());

    const erc20TokenContract = new this.web3.eth.Contract(
      ERC20ABI,
      mintInformations.tokenAddress
    );

    mintInformations["coinSupply"] = parseInt(this.web3.utils.fromWei(await erc20TokenContract.methods.totalSupply().call(), "ether"));
    mintInformations["userBalance"] = parseInt(this.web3.utils.fromWei(await erc20TokenContract.methods.balanceOf(userAddress).call(), "ether"));
    mintInformations["coinSymbol"] = await erc20TokenContract.methods.symbol().call();

    const blueprint = await this.getBlueprint(blueprintId, diamondCollectionContract);
    
    mintInformations["cardMaxSupply"] = parseInt(blueprint.maxSupply);
    
    if (parseInt(blueprint.maxSupply) > 100000000000000000) {
      mintInformations["cardMaxSupply"] = "1000";
    }

    const blueprintProperties = await this.getBlueprintProperties(blueprintId, diamondCollectionContract);

    if (parseInt(blueprintProperties.ruleSetId) == 1) {
      const rulesetContract = new this.web3.eth.Contract(
        RulesetABI,
        collectionDetails.ruleSetAddress
      );

      mintInformations["maxMint"] = parseInt(await rulesetContract.methods.mintAmountLimit().call());
    }

    console.log("mintInformations");
    console.log(mintInformations);

    return mintInformations;
  }

  async verifyAllowance(userAddress, coinsToLock, mintInformations) {
    const erc20TokenContract = new this.web3.eth.Contract(
      ERC20ABI,
      mintInformations.tokenAddress
    );

    console.log("user address");
    console.log(userAddress);
    console.log("lockerRoomAddress");
    console.log(mintInformations.lockerRoomAddress);

    let allowance = await erc20TokenContract.methods.allowance(userAddress, mintInformations.lockerRoomAddress).call();
    allowance = this.web3.utils.fromWei(allowance, "ether");

    console.log("allowance");
    console.log(allowance);
    console.log("coinsToLockInWei");
    console.log(coinsToLock);

    if (allowance >= coinsToLock) {
      console.log("true");
      return true;
    } else {
      console.log("false");
      return false;
    }
  }

  async setAllowance(coinsToLock, mintInformations) {
    const MAX_ALLOWANCE = this.web3.utils.toWei(coinsToLock, "ether");

    const tx = {
      address: mintInformations.tokenAddress,
      abi: ERC20ABI,
      functionName: "approve",
      args: [mintInformations.lockerRoomAddress, MAX_ALLOWANCE],
    };

    const { request } = await prepareWriteContract(tx);
    const { hash } = await writeContract(request)
    return hash;
  }

  async mintNFTs(address, nftId, nftQuantity, lockTime, mintInformations) {
    const tx = {
      address: DIAMOND_FACTORY_CONTRACT_ADDRESS,
      abi: DiamondFactoryABI,
      functionName: "mintNFT",
      args: [mintInformations.collectionId, nftId, address, nftQuantity, lockTime],
    };

    const { request } = await prepareWriteContract(tx);
    const { hash } = await writeContract(request);
    return hash;
  }

  async waitForTransaction(txHash, confirmations) {
    const data = await waitForTransaction({
      hash: txHash,
      confirmations: confirmations
    });
    return data;
  }

  async fetchNFTCountForUser(collectionAddress, nftId, userAddress) {
    const diamondCollectionContract = new this.web3.eth.Contract(
      DiamondCollectionABI,
      collectionAddress
    );
    const NFTcount = await diamondCollectionContract.methods.balanceOf(userAddress, nftId).call();

    return parseInt(NFTcount);
  }

  async fetchActiveBlueprint(blueprintId, diamondCollectionContract, collection, rulesetContract) {
    const activeBlueprint = [];
    const blueprint = await this.getBlueprint(blueprintId, diamondCollectionContract);
    const blueprintProperties = await this.getBlueprintProperties(blueprintId, diamondCollectionContract);
    const mintStatus = this.getMintStatus(parseInt(blueprintProperties.startDate), parseInt(blueprintProperties.endDate));
    const properties = {
      startDate: parseInt(blueprintProperties.startDate) * 1000,
      endDate: parseInt(blueprintProperties.endDate) * 1000,
      creationDate: Date(parseInt(blueprintProperties.creationDate) * 1000),
      artist: blueprint.creatorName,
      description: blueprint.description,
      price: this.web3.utils.fromWei(blueprintProperties.price, 'ether'),
      minted: parseInt(blueprint.minted),
      imageURI: collection.imageBaseURI + "" + blueprintId + "" + blueprint.fileExtension,
      mintStatus: mintStatus
    }

    if (blueprintId == 0) {
      properties.minted = properties.minted-9;
    }

    // Replace the uppercase month with lowercase and add the dot.
    let creationDateString = properties.creationDate.toLocaleString('fr-FR', dateOptions).replace(
      /([A-Za-z]+)\./, 
      (match) => match.toLowerCase()
    );

    // Format the string to the desired format "03 nov. 2023 - 14:24"
    let parts = creationDateString.split(' ');
    creationDateString = `${parts[2]} ${parts[1]}. ${parts[3]} - ${parts[4]}`;

    properties["creationDateString"] = creationDateString;

    // price
    if (properties.price <= '0.00000000000000001') {
      properties.price = '';
    }

    console.log("properties");
    console.log(properties);

    // check maxSupply
    if (parseInt(blueprint.maxSupply) > 100000000000000000) {
      properties.maxSupply = "∞";
    } else if (properties.endDate > Date.now() && properties.minted < blueprint.maxSupply) {
      properties.maxSupply = "" + parseInt(blueprint.maxSupply);
      if (properties.endDate > Date.now() && properties.startDate < Date.now()) {
        properties.mintStatus = 'minting';
      }
    } else {
      properties.maxSupply = "" + parseInt(blueprint.minted);
      properties.mintStatus = 'complete';
    }

    // check blueprint ruleSetId
    if (parseInt(blueprintProperties.ruleSetId) == 1) {
      if (properties.minted < parseInt(properties.maxSupply)) {
        let halwayMintTime = await rulesetContract.methods.halfwayPoint().call();
        const currentDate = Date.now();
        properties["maxMint"] = await rulesetContract.methods.mintAmountLimit().call();
        
        if ( (properties.minted > (parseInt(properties.maxSupply) / 2)) && (parseInt(halwayMintTime) > currentDate/1000) ) {
          properties.mintStatus = 'not-started';
          properties.startDate = parseInt(halwayMintTime) * 1000;
        }

        properties["halwayMintTime"] = parseInt(halwayMintTime) * 1000;
      }
    }

    // only if accepted
    if (this.isActiveBlueprint(blueprintProperties)) {
      activeBlueprint.push({ ...blueprint, ...properties });
    }
    return activeBlueprint;
  }
}
export default CollectionService;