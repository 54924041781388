import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage
    },
    {
        path: '/c21-coin',
        name: 'The Coin',
        // TODO: create this component
        component: () => import(/* webpackChunkName: "collections" */ '../views/CoinPage.vue')
    },
    {
        path: '/c21-cards',
        name: 'The Cards',
        // TODO: create this component
        component: () => import(/* webpackChunkName: "collections" */ '../views/CardsPage.vue')
    },
    {
        path: '/collections',
        name: 'Collections',
        // TODO: create this component
        component: () => import(/* webpackChunkName: "collections" */ '../views/collections/CollectionsList.vue')
    },
    {
        path: '/collections/:collectionAddress',
        name: 'Collection',
        // component for the collection page
        component: () => import(/* webpackChunkName: "collection" */ '../views/collections/CollectionView.vue'),
        props: true  // this allows the route to pass the dynamic segment as a prop to the component
    },
    {
        path: '/collections/:collectionAddress/:nftId',
        name: 'NFTDetail',
        // component for the NFT detail page
        component: () => import(/* webpackChunkName: "nftdetail" */ '../views/collections/NFTDetailView.vue'),
        props: true  // this passes both dynamic segments as props to the component
    },
    {
        path: '/thecube',
        name: 'TheCube',
        // TODO: create this component
        component: () => import(/* webpackChunkName: "thecube" */ '../views/TheCube.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
